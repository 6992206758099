<template>
  <div>
    <div id="remarketing-page">
      <div v-if="marketingPlan">
        <div class="flex-row">
          <h1 class="h1-title flex-grow">{{ marketingPlan.title }}</h1>
          <secondary-button
            :disabled="!(!marketingPlan.sent_date && selectedForms.length > 0)"
            title="Remove"
            class="margin-right-05"
            v-on:click="deleteMessage()"
          />
          <secondary-button
            :disabled="marketingPlan.sent_date"
            :title="blastSending ? 'Sending' : 'Send Blast!'"
            class="margin-right-05"
            v-on:click="sendBlast()"
          />
          <secondary-button
            class="margin-right-05"
            :disabled="!marketingPlan.sent_date"
            title="Collect Respond Requests"
            v-on:click="downloadCSV()"
          />
        </div>
        <p class="margin-top-2" v-if="marketingPlan.sent_date">Sent Time: {{ formatDate(marketingPlan.sent_date) }}</p>

        <div class="results-table-container">
          <results-table
            class="margin-top"
            :checkBox="true"
            :showPaginatorBar="false"
            availableSearchMode="remarketing"
            @formChecked="formChecked"
            ref="resultsTable"
          />
        </div>
      </div>
      <paginator-bar
        v-model:page="page"
        :pageBounds="pageBounds()"
        :loadedItemsCount="marketingPlan ? marketingPlan.messages.results.length : 0"
        :loading="loading"
        :hasMorePages="marketingPlan && marketingPlan.messages.next != null"
        @loadMore="getNextForms()"
      />

      <base-modal-view
        title="Blast Send!"
        message="Your Text Blast has been sent. Check back on this page later to see the responses."
        v-if="blastSent"
        @cancel="blastSent = false"
      />
    </div>
  </div>
</template>

<script>
import {
  fetchMarketingPlan,
  sendMarketingPlanBlast,
  removeMarketingPlanMessages,
  fetchMoreMessages
} from "../../../api/Marketing.js";
import { downloadCSV } from "../../../api/Form";
import { mapActions } from "vuex";
import { endpoints } from "../../../api/axiosHandler.js";
import { pageBounds } from "../../../helpers/v2/utilityHelpers";

import BaseModalView from "../../../components/Modals/v2/BaseModalView.vue";

import SecondaryButton from "../../../components/Buttons/v2/SecondaryButton.vue";
import PaginatorBar from "../../../components/Common/PaginatorBar.vue";
import ResultsTable from "../../../components/Views/v2/ResultsTable.vue";
import _ from "lodash";

export default {
  name: "Marketing",
  components: { ResultsTable, SecondaryButton, BaseModalView, PaginatorBar },
  data: function () {
    return {
      marketingPlan: null,
      blastSent: false,
      blastSending: false,
      loading: false,
      selectedForms: [],
      page: 1
    };
  },
  methods: {
    fetchMarketingPlan: function () {
      this.loading = true;
      fetchMarketingPlan(this.$route.params.id)
        .then(response => {
          this.loading = false;
          this.marketingPlan = response;
          this.setForms(this.mappedFormsWithMessage());
        })
        .catch(error => {
          this.loading = false;
          this.addError(error);
        });
    },
    deleteMessage: function () {
      this.selectedForms.forEach(form => {
        let messageId = form.message.id;
        removeMarketingPlanMessages(messageId)
          .then(() => {
            this.marketingPlan.messages.results = this.marketingPlan.messages.results.filter(
              message => message.id != messageId
            );
            this.marketingPlan.messages.count = this.marketingPlan.messages.count - 1;
            this.setForms(this.mappedFormsWithMessage());
          })
          .catch(error => {
            this.addError(error);
          });
      });
    },
    sendBlast: function () {
      if (this.blastSending) {
        return;
      }
      this.blastSending = true;
      sendMarketingPlanBlast(this.$route.params.id)
        .then(() => {
          this.blastSent = true;
          this.blastSending = false;
          this.marketingPlan.sent_date = new Date();
        })
        .catch(error => {
          this.blastSending = false;
          this.addError(error);
        });
    },
    formatDate(dateString) {
      if (dateString) {
        let convertedDate = new Date(dateString);
        return convertedDate.toDateString() + ", " + convertedDate.toLocaleTimeString();
      } else {
        return "Not Sent Yet!";
      }
    },
    selectedForm: function (id) {
      if (this.editing) {
        return;
      }
      if (!this.checkBox) {
        let routeData = this.$router.resolve({
          name: "Form",
          params: { id: id }
        });
        window.open(routeData.href, "_blank");
      }
    },
    mappedFormsWithMessage: function () {
      return this.marketingPlan.messages.results.map(message => {
        var form = message.form;
        var messageClone = _.cloneDeep(message);
        delete messageClone["form"];
        form.message = messageClone;
        return form;
      });
    },
    slicedForms: function () {
      let bounds = this.pageBounds();
      var toReturn = this.marketingPlan.messages.results;

      return toReturn.slice(bounds.lowBound, bounds.highBound);
    },
    pageBounds() {
      return pageBounds(this.page);
    },
    formChecked: function (selectedForms) {
      this.selectedForms = selectedForms;
    },
    downloadCSV: function () {
      downloadCSV(endpoints.marketing + this.marketingPlan.id + "/download-csv/", {})
        .then(response => {
          var blob = new Blob([response.data], { type: "csv" });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "remarketing.csv";
          link.click();
        })
        .catch(error => {
          this.addError(error);
        });
    },
    getNextForms() {
      if (this.marketingPlan.messages && this.marketingPlan.messages.next) {
        this.loading = true;
        fetchMoreMessages(this.marketingPlan.messages.next)
          .then(response => {
            this.loading = false;
            if (response) {
              this.marketingPlan.messages.next = response.next;
              response.results.forEach(message => {
                if (
                  this.marketingPlan.messages.results.filter(existingMessage => existingMessage.id == message.id)
                    .length <= 0
                ) {
                  this.marketingPlan.messages.results.push(message);
                }
              });
              this.setForms(this.mappedFormsWithMessage());
            }
          })
          .catch(error => {
            this.loading = false;
            this.addError(error);
          });
      }
    },
    ...mapActions({
      setForms: "formStore/setForms",
      addError: "errorStore/addError"
    })
  },
  watch: {
    page: function (newVal) {
      this.$refs.resultsTable.page = newVal;
    }
  },
  mounted: function () {
    this.setForms([]);
    this.fetchMarketingPlan();
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#remarketing-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}
</style>
